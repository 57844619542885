import React, { useContext } from "react"
import ContentForgotPassword from "../components/ContentForgotPassword"
import { AppContext } from "../contexts/AppContext"

/**
 * Here selected country is just used as a weay to trigger client side rendering so that css works well
 */
const ForgotPassword = () => {
  let { selectedCountry } = useContext(AppContext)
  return <>{selectedCountry && <ContentForgotPassword />}</>
}

export default ForgotPassword
