import React, { useState, useContext } from "react"
import styled from "styled-components"
import axios from "axios"
import useButtonLoader from "../../hooks/useButtonLoader"
import { NotificationManager } from "react-notifications"
import {
    COLOR_DEFAULT_TEXT_DARK,
    COLOR_SEARCH_INPUT_TEXT,
    COLOR_DEFAULT_TEXT_DISABLED,
} from "../../constants/colors"
import {
    MOBILE,
    LARGE,
    MEDIUM,
    TAB,
    LANDSCAPE,
} from "../../constants/breakpoints"

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.publicPages.signin.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.publicPages.signin.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.publicPages.signin.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.publicPages.signin.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.publicPages.signin.width}px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin: 10px auto;
  width: 100%;
`

const FormHeading = styled.h3`
  padding-top: 1.42vh;
  display: flex;
  justify-content: center;
  color: #eeeeee;
  font-weight: normal;
  margin: 0;
  ::selection {
    color: none;
    background: none;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: ${MOBILE.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.publicPages.signin.font.heading}px;
  }
  margin-bottom: 25px;
`
const FormInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #989dad;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  color: #fff;
  margin: 5px 0px;
  background: none;
  padding: 10px;
  font-size: 18px;
  ::placeholder {
    color: ${COLOR_SEARCH_INPUT_TEXT};
  }
`
const FormSubmitButton = styled.button`
  background-color: #ffffff;
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  align-self: flex-end;
  color: ${COLOR_DEFAULT_TEXT_DARK};
  ::selection {
    color: none;
    background: none;
    border: none;
  }
  :disabled {
    color: ${COLOR_DEFAULT_TEXT_DISABLED};
  }

  padding: 5px 10px;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 5px 30px;
  }
`
const ErrorMessage = styled.p`
  display: block;
  opacity: ${props => (props.hasData ? "1" : "0")};
  color: #9e0101;
  background: #fff;
  font-size: 1.5rem;
  border-left: 4px solid #9e0101;
  padding: 5px 15px;
  transition: opacity 500ms linear;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 10px 30px;
  }
`
const FormHelpers = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between;
`


const ContentForgotPassword = props => {
    const [formData, setFormData] = useState({
        email: ""
    })

    const [loaderElement, setLoader] = useButtonLoader("Get a new password", "Processing...")

    const [submitted, setSubmitted] = useState(false)
    const [errorData, setErrorData] = useState("")
    const [displayError, setDisplayError] = useState(false)
    const showError = error => {
        setErrorData(error)
        setDisplayError(true)
        setTimeout(() => {
            hideError()
        }, 3000)
    }

    const hideError = () => {
        setDisplayError(false)
    }

    const validateAndSend = e => {
        setLoader(true)
        e && e.preventDefault()
        if (!formData.email) {
            showError("Please enter email")
            // clear error after some time
            setLoader(false)
            return
        }
        if (formData.email) {
          const emailCheck = "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
          if (!formData.email.match(emailCheck)){
            showError("Please enter valid email")
            setLoader(false)
            return
          }
        }

        axios
            .post(`${process.env.STRAPI_SERVER_URL}/auth/forgot-password`, {
                email: formData.email
            })
            .then(response => {
                // Handle success.
                NotificationManager.success("Email with password reset instructions is sent to this email, please check!")
                setLoader(false)
            })
            .catch(error => {
                // Handle error.
                NotificationManager.error("This email does not exist!")
                setLoader(false)
            });
    }

    return (
        <LoginWrapper>
            <Form>
                <FormHeading>Forgot your password?</FormHeading>

                <FormInput
                    type="email"
                    placeholder="Email"
                    onChange={e => setFormData({ ...formData, email: e.target.value })}
                    onKeyUp={event => event.keyCode === 13 && validateAndSend()}
                />
                <FormHelpers>
                    <FormSubmitButton
                        ref={loaderElement}
                        type="button"
                        disabled={submitted}
                        onSubmit={validateAndSend}
                        onClick={validateAndSend}
                    >Get a new password</FormSubmitButton>
                </FormHelpers>
            </Form>
            <ErrorMessage hasData={displayError ? true : false}>
                {errorData}
            </ErrorMessage>
        </LoginWrapper>
    )
}

export default ContentForgotPassword
